import React, { useRef, useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CCard,
  CBtnList,
  CInlineLinkList,
  CSliderMedia,
  CSectTitle03,
  CHeadingCard,
  CGeneralModal,
  CTable,
  CFaqList,
} from '../../../../components/_index';
import infoChoice from '../../../../utils/info-choice';
import infoGet from '../../../../utils/info-get';
import '../../../../assets/_sass/page/lounge.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const [modalFlag, setModalFlag] = useState(false);
  const [modalFlag02, setModalFlag02] = useState(false);

  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };

  const openModal02 = (e: any) => {
    e.stopPropagation();
    setModalFlag02(!modalFlag02);
  };
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        type="ja"
        data={{
          title: {
            main: 'エグゼクティブラウンジ',
            sub: <>EXECUTIVE LOUNGE</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/floor/lounge/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/floor/lounge/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <div className="p_lounge">
        <section className="p_intro">
          <LWrap>
            <h2 className="c_articleHeadingLv4 u_fwm">
              ゆったりとした空間で、
              <br />
              最高のひとときを。
            </h2>
            <p>
              スイート、エグゼクティブフロアご宿泊者専⽤ラウンジでのご朝食、ティータイム、
              <br className="u_pc" />
              カクテルタイムのほか限定特典もご⽤意し、さらに⾄福のひとときへ。
              <br />
              お客様が⼼も身体も満たされる滞在へ、まごころ込めてお招きいたします。
            </p>
          </LWrap>
        </section>
        <section className="bg_gradient">
          <div className="p_timeLine">
            {/* DAY 1 */}
            <div className="day1">
              <div className="p_daySticky u_colorWhite">
                <span>DAY</span>1
              </div>
              <LWrap>
                <div className="p_timeLine__item u_colorWhite">
                  <div className="title">
                    <span className="time">15:00</span>
                    Check In
                  </div>
                  <CSliderMedia
                    data={[
                      {
                        img: [
                          {
                            img: {
                              src: '/assets/images/stay/floor/lounge/img_lounge.png',
                              alt: '',
                            },
                          },
                          {
                            img: {
                              src: '/assets/images/stay/floor/lounge/img_lounge02.png',
                              alt: '',
                            },
                          },
                          {
                            img: {
                              src: '/assets/images/stay/floor/lounge/img_lounge03.png',
                              alt: '',
                            },
                          },
                        ],
                        text: (
                          <>
                            <p>
                              ラウンジのお席でチェックイン後、そのまま優雅なティータイムを。生ビールやスパークリングワインといったアルコール類のほか、オーガニックティーブランド「ART
                              OF
                              TEA」とともに、スコーンやパウンドケーキなどの軽食が楽しめる。
                            </p>
                            <p>ティータイム　11:00～16:30</p>
                          </>
                        ),
                      },
                    ]}
                  />
                </div>

                <div className="p_timeLine__item u_colorWhite">
                  <div className="title">
                    <span className="time">16:00</span>
                    Spa & Gym
                  </div>
                  <CSliderMedia
                    data={[
                      {
                        img: [
                          {
                            img: {
                              src: '/assets/images/stay/floor/lounge/img_lounge04.png',
                              alt: '',
                            },
                          },
                          {
                            img: {
                              src: '/assets/images/stay/floor/lounge/img_lounge05.png',
                              alt: '',
                            },
                          },
                          {
                            img: {
                              src: '/assets/images/stay/floor/lounge/img_lounge06.png',
                              alt: '',
                            },
                          },
                        ],
                        text: (
                          <>
                            <p>
                              お得に施術を受けられるリラクゼーションサロン「LA
                              VILLA Spa by L’OCCITANE
                              Tokyo」、無料で利用可能な各種トレーニングマシンやストレッチエリアを備えた「ご宿泊者専用ジム」でリフレッシュ。
                            </p>
                            <CBtnList
                              data={[
                                {
                                  label: 'ご宿泊者専用ジム',
                                  color: 'borderWhite',
                                  icon: 'modal',
                                  tag: 'button',
                                  onClick: openModal02,
                                  link: {
                                    href: '#',
                                  },
                                },
                              ]}
                            />
                            <p
                              className="u_mt15"
                              onClick={openModal}
                              style={{ cursor: 'pointer' }}
                            >
                              サロンご優待について
                              <i className="icon-modal"></i>
                            </p>
                          </>
                        ),
                      },
                    ]}
                  />
                </div>
                {modalFlag && (
                  <CGeneralModal onClose={openModal}>
                    <>
                      <div className="p_lounge_modal_inner">
                        <p className="c_headingLv4 u_tal u_fwm">ご優待</p>
                        <ul className="c_circleList">
                          <li>一部トリートメントのご優待（20%割引）</li>
                          {/* <li>
                            上記ご優待をご検討中のお客様に5分間のハンドトリートメントの無料トライアル
                          </li> */}
                        </ul>
                        {/* <ul className="c_noteList u_mt15">
                          <li>
                            「LA VILLA Spa by L’OCCITANE
                            Tokyo」のインスタグラムをフォローもしくはダイレクトメールのご登録をしていただいた方が対象です。
                          </li>
                          <li>
                            本優待はご予約制ですが、チェックイン前のご予約は承りかねます。
                          </li>
                          <li>
                            カウンセリングなどを含めた所要時間は約15分となります。
                          </li>
                        </ul> */}
                        <ul className="c_noteList">
                          <li>
                            ご優待の内容は都合により変更になる場合がございます。
                          </li>
                          <li>
                            予約状況によってお受けできない場合もございますので、あらかじめご了承ください。
                          </li>
                        </ul>
                      </div>
                    </>
                  </CGeneralModal>
                )}
                {modalFlag02 && (
                  <CGeneralModal
                    onClose={openModal02}
                    exClass={'c_generalModal_full'}
                  >
                    <div className="c_generalModal_facilityGym">
                      <div>
                        <img
                          src="/assets/images/stay/facility/img_stay_facility15.png"
                          alt=""
                        />
                      </div>
                      <div>
                        <img
                          src="/assets/images/stay/facility/img_stay_facility18.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <p className="sectLead u_mb80_pc u_pb120_sp">
                      和の温かみある雰囲気に、モダンでシックなデザインを取り入れた空間には、各種トレーニングマシンを備えたジムエリアと、バランスボールやヨガマットが置かれたストレッチエリアが広がります。
                      <br />
                      ご宿泊のお客様専用の24時間ご利用いただけるプライベート空間で、心地良く充実したホテルライフをお過ごしください。
                    </p>
                    <CSectTitle03 title="施設概要" exClass=" u_mb60" />
                    <CTable
                      data={[
                        {
                          title: '営業時間',
                          content: <>0:00~24:00</>,
                        },
                        {
                          title: 'ご利用料金',
                          content: (
                            <>
                              <ul className="c_circleList">
                                <li>
                                  スイート、エグゼクティブフロアご宿泊者　無料
                                </li>
                                <li>スタンダードフロアご宿泊者　　1,100円</li>
                              </ul>
                              <ul className="c_noteList u_mt10">
                                <li>料金には消費税が含まれます。</li>
                                <li>ウェア・シューズはご持参ください。</li>
                                <li>
                                  16歳未満のお客様のご利用はご遠慮いただいております。
                                </li>
                              </ul>
                            </>
                          ),
                        },
                        {
                          title: '設備',
                          content: (
                            <>
                              <div className="c_3col">
                                <div className="col">
                                  <p className="u_fwb u_mb10">ジムエリア</p>
                                  <ul className="c_circleList">
                                    <li>有酸素マシーン 9台</li>
                                    <li>ウエイトトレーニングマシーン 10台</li>
                                  </ul>
                                </div>
                                <div className="col">
                                  <p className="u_fwb u_mb10">
                                    ストレッチエリア
                                  </p>
                                  <ul className="c_circleList">
                                    <li>ヨガマット</li>
                                    <li>バランスボール</li>
                                    <li>マット　他、トレーニング備品</li>
                                  </ul>
                                </div>
                                <div className="col">
                                  <p className="u_fwb u_mb10">共通</p>
                                  <ul className="c_circleList">
                                    <li>
                                      ウォーターサーバー（温水･常温･冷水対応）
                                    </li>
                                    <li>フェイスタオル</li>
                                  </ul>
                                </div>
                              </div>
                              <ul className="c_noteList u_mt10">
                                <li>更衣室、シャワー設備はございません。</li>
                              </ul>
                            </>
                          ),
                        },
                      ]}
                    />
                  </CGeneralModal>
                )}

                <div className="p_timeLine__item u_colorWhite">
                  <div className="title">
                    <span className="time">17:00</span>
                    Lounge
                  </div>
                  <CSliderMedia
                    data={[
                      {
                        img: [
                          {
                            img: {
                              src: '/assets/images/stay/floor/lounge/img_lounge07.png',
                              alt: '',
                            },
                          },
                          {
                            img: {
                              src: '/assets/images/stay/floor/lounge/img_lounge08.png',
                              alt: '',
                            },
                          },
                        ],
                        text: (
                          <>
                            <p>
                              シェフ自慢のアミューズでお食事前の一杯。東京の夕景を眺めながらゆったり。眼下にはハイウェイをテールランプが流れる非日常の空間を体験して。
                            </p>
                            <p>カクテルタイム　17:00～20:00</p>
                          </>
                        ),
                      },
                    ]}
                  />
                </div>

                <div className="p_timeLine__item u_colorWhite">
                  <div className="title">
                    <span className="time">18:30</span>
                    Dinner
                  </div>
                  <CSliderMedia
                    data={[
                      {
                        img: [
                          {
                            img: {
                              src: '/assets/images/stay/floor/lounge/img_lounge09.png',
                              alt: '',
                            },
                          },
                          {
                            img: {
                              src: '/assets/images/stay/floor/lounge/img_lounge10.png',
                              alt: '',
                            },
                          },
                          {
                            img: {
                              src: '/assets/images/stay/floor/lounge/img_lounge11.png',
                              alt: '',
                            },
                          },
                          {
                            img: {
                              src: '/assets/images/stay/floor/lounge/img_lounge12.png',
                              alt: '',
                            },
                          },
                        ],
                        text: (
                          <>
                            夜景を楽しめる20Fの鉄板焼
                            すみだをはじめ、和洋中お好きなレストランでご⼣⾷を。国賓や世界のVIPをもてなすサービスや贅沢な味わいに舌鼓。
                            <CInlineLinkList
                              align="left"
                              color="white"
                              data={[
                                {
                                  label: 'レストラン＆バー',
                                  link: {
                                    href: '/restaurants/',
                                  },
                                  icon: 'arrow',
                                },
                              ]}
                            />
                          </>
                        ),
                      },
                    ]}
                  />
                </div>

                <div className="p_timeLine__item u_colorWhite">
                  <div className="title">
                    <span className="time">21:00</span>
                    Room
                  </div>
                  <CSliderMedia
                    data={[
                      {
                        img: [
                          {
                            img: {
                              src: '/assets/images/stay/floor/lounge/img_lounge13.png',
                              alt: '',
                            },
                          },
                          {
                            img: {
                              src: '/assets/images/stay/floor/lounge/img_lounge14.png',
                              alt: '',
                            },
                          },
                        ],
                        text: (
                          <>
                            食後は全室シモンズ社製のベッドを備えるお部屋が快適なご滞在を叶えます。L'OCCITANE製のアメニティは、心も身体も癒してくれるはず。
                          </>
                        ),
                      },
                    ]}
                  />
                </div>
              </LWrap>
            </div>

            {/* DAY 2 */}
            <div className="day2">
              <div className="p_daySticky">
                <span>DAY</span>2
              </div>
              <LWrap>
                <div className="p_timeLine__item circleBlack">
                  <div className="title">
                    <span className="time">7:00</span>
                    Breakfast
                  </div>
                  <CSliderMedia
                    data={[
                      {
                        img: [
                          {
                            img: {
                              src: '/assets/images/stay/floor/lounge/img_lounge15.png',
                              alt: '',
                            },
                          },
                        ],
                        text: (
                          <>
                            <p>
                              エグゼクティブラウンジでの朝食時のみに提供する、地元老舗店の出汁と和の食材を使った「粋オムレツ」が大好評。辛子明太子と大葉、鶏そぼろと野沢菜、肉じゃがの3種類をご用意。
                            </p>
                            <p>ご朝食　7:00~10:00</p>
                          </>
                        ),
                      },
                    ]}
                  />
                </div>

                <div className="p_timeLine__item circleBlack">
                  <div className="title">
                    <span className="time">10:00</span>
                    Sightseeing
                  </div>
                  <CSliderMedia
                    data={[
                      {
                        img: [
                          {
                            img: {
                              src: '/assets/images/stay/floor/lounge/img_lounge16.png',
                              alt: '',
                            },
                          },
                          {
                            img: {
                              src: '/assets/images/stay/floor/lounge/img_lounge17.png',
                              alt: '',
                            },
                          },
                          {
                            img: {
                              src: '/assets/images/stay/floor/lounge/img_lounge18.png',
                              alt: '',
                            },
                          },
                        ],
                        text: (
                          <>
                            <p>
                              ロイヤルパークホテルのお部屋のカードキーを提示すると、周辺のお店で嬉しい特典が♪レトロ感漂う日本橋・人形町で、お買い物をしながら街の探索を。
                            </p>
                            <CInlineLinkList
                              align="left"
                              data={[
                                {
                                  label:
                                    'ルームキーでお得 粋な街 日本橋・人形町へ出かけよう！',
                                  link: {
                                    href: '/about/enjoy_the_city/',
                                  },
                                  icon: 'arrow',
                                },
                              ]}
                            />
                          </>
                        ),
                      },
                    ]}
                  />
                </div>

                <div className="p_timeLine__item circleBlack">
                  <div className="title">
                    <span className="time">12:00</span>
                    Check Out
                  </div>
                  <p className="p_timeLine__text">
                    お部屋の空き状況によっては、15:00までチェックアウトの延⻑が可能。成⽥・⽻⽥空港リムジンバスのターミナルであるT-CATに隣接し、⽔天宮前駅にも直結なのでアクセスも抜群。
                  </p>
                </div>
              </LWrap>
            </div>
          </div>
        </section>

        <section className="l_sect02 p_lounge_other_service">
          <LWrap>
            <CSectTitle03 title="その他サービス" />
            <CCard
              col={2}
              data={[
                {
                  img: {
                    src: '/assets/images/stay/floor/lounge/img_service.png',
                    alt: '',
                  },
                  title: <>アニバーサリープランナー</>,
                  content: (
                    <>
                      専属のアニバーサリープランナーが大切な記念日をフルサポート。
                      <br className="u_pc" />
                      ご宿泊日の5日前までご要望を承りますので、お気軽にお申し付けください。
                    </>
                  ),
                  btn: {
                    label: '記念日・プロポーズ',
                    link: {
                      href: '/anniversary/',
                    },
                    color: 'borderBlack',
                  },
                },
                {
                  img: {
                    src: '/assets/images/stay/floor/lounge/img_service02.png',
                    alt: '',
                  },
                  title: <>ミーティングルーム</>,
                  content: (
                    <>
                      ご宿泊のお客様がご利用いただける専用ルーム。事前ご予約制で2時間無料。
                    </>
                  ),
                  btn: {
                    label: '滞在中のサービス',
                    link: {
                      href: '/stay/facility/',
                    },
                    color: 'borderBlack',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="l_sect02 p_loungeRoomBg">
          <LWrap>
            <CSectTitle03
              title={
                <>
                  エグゼクティブラウンジを
                  <br />
                  ご利用いただける客室
                </>
              }
            />
            <CHeadingCard
              col={3}
              style={{ justifyContent: 'center' }}
              data={[
                {
                  link: {
                    href: '/stay/floor/suite/',
                  },
                  img: {
                    src: '/assets/images/stay/floor/lounge/img_lounge_room.png',
                    alt: 'スイート',
                  },
                  title: <>スイート</>,
                },
                {
                  link: {
                    href: '/stay/floor/club_floor/',
                  },
                  img: {
                    src: '/assets/images/stay/floor/lounge/img_lounge_room02.png',
                    alt: 'エグゼクティブフロア',
                  },
                  title: <>エグゼクティブフロア</>,
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="l_sect02">
          <LWrap>
            <CSectTitle03 title="よくあるご質問" />
            <CFaqList
              data={[
                {
                  q: '宿泊している方と会いたいのですが、エグゼクティブラウンジに訪問することはできますか?',
                  a: (
                    <>
                      スイート、エグゼクティブフロアにご宿泊の方をご訪問されるお客様は、エグゼクティブラウンジを有料でご利用いただけます。
                      <br />
                      朝食 4,000円、ティータイム 3,500円、カクテルタイム 9,000円
                      <br />
                      ※料金には消費税・サービス料が含まれます。
                    </>
                  ),
                },
                {
                  q: 'エグゼクティブラウンジは子供も利用できますか?',
                  a: (
                    <>
                      朝食、ティータイムは、添い寝のお子様は無料でご利用いただけます。
                      <br />
                      17:00以降のカクテルタイムにつきましては、添い寝の有無にかかわらず小学生以下のお子様のご入場をご遠慮いただいております。
                    </>
                  ),
                },
              ]}
            />

            <CBtnList
              exClass="u_mt30"
              data={[
                {
                  label: 'よくあるご質問',
                  link: {
                    href: '/faq/',
                  },
                  color: 'bgWhite03',
                },
              ]}
            />
          </LWrap>
        </section>
      </div>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: '宿泊',
              path: '/stay/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
